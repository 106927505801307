import api from "api";

export class Extra {
    id: number;
    username: string;
    coord: string;
    description: string;
    status: string;
    start_date: string;
    end_date: string;
    create_date: string;

    constructor(
        id: number,
        username: string,
        coord: string,
        description: string,
        status: string,
        start_date: string,
        end_date: string,
        create_date: string
    ) {
        this.id = id;
        this.username = username;
        this.coord = coord;
        this.description = description;
        this.status = status;
        this.start_date = start_date;
        this.end_date = end_date;
        this.create_date = create_date;
    }
}

export interface ExtraPage {
    lastPage: number,
    extra: Extra[]
}

export class ExtraService {
    async geExtras(
        currentPage: number,
        numberOfLines: number,
        searchValue?: string
    ):
        Promise<ExtraPage> {

        const getParams = () => {
            let params = {
                page: (currentPage - 1).toString(),
                'qtd-lines': numberOfLines.toString(),
                'collaborator': searchValue ?? ""
            }

            const urlSearchParams = new URLSearchParams(params);
            return urlSearchParams.toString()
        };

        const { data } = await api.get(`/hours/admin/extra?${getParams()}`)
        const lastPage = 100
        const extras = data

        return { 
            lastPage: lastPage,
            extra: extras
        }
    }
}
import React from "react";
import { Container, Row } from "react-bootstrap";
import EmptyStateImage from "images/empty_box.svg";
import EmptyState from "components/EmptyState";
import { Extra } from "../List/ExtraService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface ExtraListProps {
    extra?: Extra[],
    onItemClicked:(extra: Extra) => void
}

export default function ExtraList(props: ExtraListProps) {
    
    return (
        <Container>
            <Row className="g-2">
                <h3 className="col"> Horas Extras </h3>
            </Row>
            <div>
                {props?.extra ?
                    <DataTable value={props?.extra} stripedRows tableStyle={{ minWidth: '50rem' }}>
                        <Column field="username" header="Colaborador"></Column>
                        <Column field="description" header="Descrição"></Column>
                        <Column field="status" header="Status"></Column>
                        <Column field="start_date" header="Início"></Column>
                        <Column field="end_date" header="Fim"></Column>
                        <Column field="coord" header="Responsável"></Column>
                        <Column field="create_date" header="Criado em"></Column>
                    </DataTable>
                     : <EmptyState
                        className="mt-3"
                        message={"Nenhuma hora extra encontrada..."}
                        img={EmptyStateImage} />
                }
            </div>
        </Container>
    );
}
import React from "react";
import { Row, Alert } from "react-bootstrap";

export class ExtraFeedback {
    title: string;
    message: string;
    private onClick: () => void;
    show: boolean;

    constructor(title: string, message: string, onClick: () => void) {
        this.title = title
        this.message = message
        this.onClick = onClick
        this.show = true
    }

    clickToConfirm() {
        this?.onClick && this.onClick()
    }

    static createFeedback(
        title: string,
        message: string,
        onClick = () => { }
    ) {
        return new ExtraFeedback(title, message, onClick)
    }
}
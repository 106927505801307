import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { ExtraHeaderTitle } from "../components/ExtraHeaderTitle";
import { SearchComponent } from "pages/Team/components/SearchComponent";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { Extra, ExtraPage, ExtraService } from "./ExtraService";
import { AxiosError } from "axios";
import { PageSelector } from "components/PageSelector";
import { useNavigate, useSearchParams } from "react-router-dom";
import { URL_PAGES } from "utils/constants";
import EasyFeedbackModal from "components/EasyFeedbackModal";
import { ExtraFeedback } from "../components/ExtraFeedback";
import ExtraContainer from "../components/ExtraContainer";
import ExtraList from "../components/ExtraList";

interface ExtraListProps {
    onSubmitSearch: () => void,
    onSearchValueChanged: (value?: string) => void,
    onBackPressed: () => void,
    searchValue?: string
}

function Extras(props: ExtraListProps) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [extraPage, setExtras] = useState<ExtraPage>();
    const [totalPages, setTotalPages] = useState(0)
    const [searchValue, setSearchValue] = useState<string>()
    const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") ?? 1));
    const [userWithoutTeam, setUserWithoutTeam] = useState(false)
    const [feedback, setFeedback] = useState<ExtraFeedback>();

    const onChangePage = (newPage: number) => {
        setCurrentPage(newPage)
        setSearchParams({ page: newPage.toString() })
    };

    const handleChange = () => {
        getExtraList();
    }

    const getExtraList = () => {
        const extraService = new ExtraService()
        setLoading(true)

        extraService.geExtras(currentPage, 16, searchValue)
            .then((response: ExtraPage) => {
                console.log("Response", response)
                setExtras(response)
                setTotalPages(response.lastPage)
            })
            .catch((error: AxiosError | any) => {
                const errorMessage = error?.response?.data?.message
                setFeedback(
                    ExtraFeedback.createFeedback(
                        "Erro ao buscar horas extras",
                        errorMessage ?? "Tivemos um problema ao buscar a lista de horas extras. Por favor, tente novamente."
                    )
                )
            })
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        getExtraList()
    }, [currentPage]);

    useEffect(() => {
        if (searchValue === "") {
            onChangePage(1);
            if (currentPage === 1) {
                getExtraList();
            }
            return
        }
    }, [searchValue]);

    useEffect(() => {
        handleChange();
    }, [userWithoutTeam]);
    
    return (<Container>

        <ExtraHeaderTitle
            title="Listagem Hora Extra"
            description="Exibir lista de horas extras." />

        <Row className="g-4">
            <Col sm={5} lg={6}>
                <div className="row g-3">
                    <Col lg={2}>
                        <Button variant="outline-primary" onClick={() => navigate(-1)}>
                            Voltar
                        </Button>
                    </Col>
                </div>
            </Col>

            <SearchComponent
                placeholder="Usuário do Colaborador"
                className="col-md-6"
                value={searchValue}
                onSubmitSearch={() => getExtraList()}
                onSearchValueChanged={setSearchValue}
            />
        </Row>

        <ExtraContainer className="mt-3">
            <Loading isLoading={isLoading} />
            
            <ExtraList extra={extraPage?.extra}
                onItemClicked={(extra: Extra) => navigate(URL_PAGES.EXTRA, { state: { extra: extra } })} />

            <PageSelector
                onSelectPage={onChangePage}
                pageSelected={currentPage}
                numberOfPages={totalPages} />

        </ExtraContainer>
        <EasyFeedbackModal
            title={feedback?.title}
            description={feedback?.message}
            show={feedback?.show ?? false}
            listener={() => setFeedback(undefined)} />

    </Container>

    );
};
export default Extras

import { PERMISSIONS, URL_PAGES } from "utils/constants"
import { session } from "utils"
import EscalationIcon from "./images/ic_escalation.svg";
import ExceptionIcon from "./images/ic_hour_home.svg";
import TeamsIcon from "./images/ic_teams.svg";
import NotificationsIcon from "./images/ic_notification.svg";
import EventsIcon from "./images/ic_events.svg";
import OnCallIcon from "./images/ic_oncall_home.svg";
import LifeEvidenceIcon from "./images/ic_life_evidence.svg";
import InvoiceIcon from "./images/ic_invoice.svg";
import CollaboratorsIcon from "./images/ic_collaborators.svg"

const FEATURES = [
    {
        url: URL_PAGES.TEAMS,
        permission: PERMISSIONS.teams_adm,
        title: "Times",
        image: TeamsIcon
    },
    {
        url: URL_PAGES.NOTIFICATIONS,
        permission: PERMISSIONS.notifications,
        title: "Notificações",
        image: NotificationsIcon
    },
    {
        url: URL_PAGES.ONCALL,
        permission: PERMISSIONS.hour_oncall_admin,
        title: "Sobreaviso",
        image: OnCallIcon
    },
    {
        url: URL_PAGES.HOUR_EXCEPTION,
        permission: PERMISSIONS.hour_exception_throw_admin,
        title: "Exceções",
        image: ExceptionIcon
    },
    {
        url: URL_PAGES.EVENTS,
        permission: PERMISSIONS.events_adm,
        title: "Eventos",
        image: EventsIcon
    },
    {
        url: URL_PAGES.EVIDENCE,
        permission: PERMISSIONS.evidence_approver,
        title: "Evidências",
        image: LifeEvidenceIcon
    },
    {
        url: URL_PAGES.ESCALATION_HOME,
        permission: PERMISSIONS.escalation_management,
        title: "Escalations",
        image: EscalationIcon
    },
    {
        url: URL_PAGES.COLLABORATORS,
        permission: PERMISSIONS.teams_adm,
        title: "Colaboradores",
        image: CollaboratorsIcon
    },
    {
        url: URL_PAGES.INVOICE,
        permission: PERMISSIONS.collaborator_invoice,
        title: "Notas fiscais",
        image: InvoiceIcon
    },
    {
        url: URL_PAGES.EXTRA,
        permission: PERMISSIONS.hour_exception_throw_admin,
        title: "Admin Extras",
        image: ExceptionIcon
    }
]

export const AVAILABLE_FEATURES =
    FEATURES.filter(item => session.permissions().includes(item.permission))
